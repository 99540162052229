import React, { PureComponent, useState, Fragment } from 'react';
// import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import _ from 'lodash';
import loadable from '@loadable/component';

// import { canUseDOM } from 'common/utils/index';
import { gotoUrl } from 'common/utils';

import {
  Container,
  Row,
  Column,
  BlockTransparent
  // Block
} from 'common/components/styleguide/grid';
import { Headline2 } from 'common/components/styleguide/Headline';

import Breadcrumbs from 'common/components/_partials/breadcrumbs/index';
import Availability from 'common/components/_partials/availability/_default/index';
import Sticker from 'common/components/_partials/sticker/_default/index.js';

// import ProductTabs from 'common/components/product/detail/_default/tabs/index';
// import Tags from 'common/components/product/detail/_default/Tags';
// import RelatedProducts from 'common/components/product/detail/_default/RelatedProducts';
// import CrosssaleProducts from 'common/components/product/detail/_default/CrosssaleProducts';
import NotFound from 'common/components/errors/404';
import Image from 'common/components/_base/Image';

import loader from 'assets/images/loader.svg';
import { makeMapStateToProps, mapDispatchToProps } from '../_default/_shared';

import SS from 'common/pages/_style/index';

import { fireGTMEvent } from 'common/utils/gtm';
import { PRODUCT_DETAIL } from 'common/constants/GTMConstants';

const BOX_ID = 70;
const BOX_HASH_VIDEO = 'VIDEO';
const Gallery = loadable(() => import('common/blocks/gallery'));
const ProductSummary = loadable(() =>
  import('common/blocks/product/detail/summary')
);
const Newsletter = loadable(
  () => import('common/blocks/functional/newsletter'),
  { ssr: false }
);
const ProductForm = loadable(props =>
  import(`common/blocks/product/detail/form`)
);
const ProductPrice = loadable(() =>
  import('common/blocks/product/detail/price')
);
const ProductCard = loadable(() => import(`common/blocks/product/card`));

const S = {};

S.Image = styled(Image)`
  width: 100%;
`;

S.ProductWrapper = styled.div`
  font-size: 14px;
  line-height: 24px;
  background-color: transparent;

  .row {
    position: relative;
    clear: both;
    width: 100%;
    overflow: visible;
  }

  .row:after {
    content: '';
    clear: both;
    display: table;
  }

  .row .col {
    width: 100%;
    float: left;
    padding: 0 15px;
    box-sizing: border-box;
    overflow: visible;
  }

  #product-detail {
  }

  .profile-photo {
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
  }

  .gallery {
    position: relative;
    margin-bottom: 40px;
  }

  .gallery .gallery-items {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow-x: scroll;
  }

  .gallery .gallery-items .gallery-item {
    display: block;
    position: relative;
    box-sizing: border-box;
    margin-right: 10px;
  }

  .gallery .gallery-items .gallery-item:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 3px solid transparent;
    box-sizing: border-box;
    transition: all 0.3s;
  }

  .gallery .gallery-items .gallery-item:hover:after {
    border-color: #56ab2f;
  }

  .gallery .gallery-items .gallery-item:last-child {
    margin-right: 0;
  }

  .gallery .gallery-item img {
    display: block;
    max-width: 125px;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
    margin: 0;
  }

  .gallery .controls {
    margin-right: 20px;
    display: block;
    position: absolute;
    bottom: 100%;
    right: 0;
  }

  .gallery .controls a {
    display: inline-block;
    background-color: #56ab2f;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: white;
    text-decoration: none;
    margin-bottom: 30px;
    text-align: center;
    padding-top: 12px;
    box-sizing: border-box;
    margin-left: 10px;
    transition: all 0.3s;
  }

  .gallery .controls a:hover {
    background-color: #48701e;
  }

  h1 {
    color: #111111;
    font-family: 'Nunito Sans';
    font-size: 32px;
    font-weight: 800;
    line-height: 43px;
    margin-bottom: 0;
  }

  .subtitle {
    opacity: 0.5;
    color: #111111;
    font-family: 'Nunito Sans';
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
  }

  .prices {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .prices .selling-price {
    color: #111111;
    font-family: 'Nunito Sans';
    font-size: 24px;
    font-weight: 800;
    line-height: 32px;
    display: inline-block;
  }

  .prices .origin-price {
    color: #111111;
    font-family: 'Nunito Sans';
    font-size: 18px;
    font-weight: 800;
    line-height: 24px;
    display: inline-block;
    text-decoration: line-through;
  }

  .add-to-cart {
    height: auto;
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
  }

  .tabs-wrapper {
    margin-top: 30px;
  }

  .tabs-wrapper .tabs {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
  }

  .tabs-wrapper .tabs span {
    display: inline-block;
    color: #56ab2f;
    font-family: Merriweather;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    padding: 13px 35px;
    cursor: pointer;
    text-decoration: none;
  }

  .tabs-wrapper .tabs span.active {
    background-color: white;
  }

  .tabs-wrapper .tab-content {
    background: white;
    padding: 20px;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
    font-family: Merriweather;
  }

  .tabs-wrapper .tab-content ul,
  .ingredients-section ul {
    padding-left: 30px;
  }

  .tabs-wrapper .tab-content ul li,
  .ingredients-section ul li {
    margin-bottom: 30px;
    list-style-type: none;
    position: relative;
  }

  .tabs-wrapper .tab-content ul li:before,
  .ingredients-section ul li:before {
    display: block;
    content: '';
    width: 10px;
    height: 10px;
    border: 1px solid #a6c080;
    position: absolute;
    left: -30px;
    top: 6px;
    border-radius: 2px;
  }

  @media (min-width: 776px) {
    .row .col {
      width: 50%;
    }
    .row .col.col-12 {
      width: 100%;
    }
    .row .col.col-5 {
      width: 41.66%;
    }
    .row .col.col-6 {
      width: 50%;
    }
    .row .col.col-8 {
      width: 66.66%;
    }
    .row .col.col-4 {
      width: 33.33%;
    }
    .row .col.col-1 {
      width: 8.33%;
    }
  }

  /*RECEPT*/

  .top-parameters {
    font-family: 'Nunito Sans';
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    margin: 50px 0;
  }

  .top-parameters .parameter {
    width: 137px;
    height: 137px;
    border: 2px solid #56ab2f;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 5px;
  }
  .top-parameters .parameter:last-child {
    margin-right: 0;
  }

  .top-parameters .parameter img {
    margin-top: 15px;
  }

  .top-parameters .parameter .parameter-name {
  }

  .top-parameters .parameter .parameter-value {
    color: #56ab2f;
    margin-bottom: 15px;
  }

  .ingredients-section {
    margin: 100px 0;
  }

  .ingredients-section h2 {
    color: #111111;
    font-family: 'Nunito Sans';
    font-size: 32px;
    font-weight: 800;
    line-height: 43px;
    margin-top: 0;
  }

  .ingredients-section img {
    max-width: 100%;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
  }

  .ingredients-section ul li {
    margin-bottom: 0;
    line-height: 28px;
  }

  .crosssale {
    background: white;
    padding: 50px 0 100px;
  }

  .crosssale .text-right {
    text-align: right;
  }

  .crosssale h2 {
    color: #111111;
    font-family: 'Nunito Sans';
    font-size: 32px;
    font-weight: 800;
    line-height: 43px;
    margin-bottom: 60px;
    margin-top: 0;
    display: none;
  }

  .crosssale .button-holder {
    text-align: center;
    display: block;
    width: 100%;
    overflow: auto;
    margin-bottom: 30px;
  }

  .crosssale .button-holder .button-group {
    display: inline-flex;
  }

  .crosssale .button-holder .button-group .button {
    color: #56ab2f;
    text-decoration: none;
    background: white;
    padding: 13px 50px;
    font-family: Merriweather;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    border: 1px solid #56ab2f;
    border-right-width: 0;
    box-sizing: border-box;
    transition: all 0.3s;
  }

  .crosssale .button-group .button:last-child {
    border-right-width: 1px;
  }

  .crosssale .button-group .button.active,
  .crosssale .button-group .button:hover {
    background-color: #56ab2f;
    color: white;
  }

  .crosssale .crosssale-carousel {
  }
  .crosssale .crosssale-carousel .no-padding {
    padding: 0;
  }
  .crosssale .crosssale-carousel .crosssale-carousel-item {
  }
  .crosssale .crosssale-carousel .crosssale-carousel-item h3 {
    color: #111111;
    font-family: 'Nunito Sans';
    font-size: 24px;
    font-weight: 800;
    line-height: 32px;
    margin: 0;
  }

  .crosssale .crosssale-carousel .crosssale-carousel-item .brand {
    color: #56ab2f;
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: 800;
    line-height: 22px;
    margin-bottom: 30px;
  }

  .crosssale .crosssale-carousel .crosssale-carousel-item .short-description {
    color: #111111;
    font-family: Merriweather;
    font-size: 14px;
    line-height: 24px;
  }

  .crosssale
    .crosssale-carousel
    .crosssale-carousel-item
    .prices
    .selling-price {
    color: #111111;
    font-family: 'Nunito Sans';
    font-size: 18px;
    font-weight: 800;
    line-height: 24px;
  }

  .crosssale .crosssale-carousel .crosssale-carousel-item img {
    max-width: 100%;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
  }

  .crosssale .crosssale-carousel .crosssale-carousel-item .controls {
    display: none;
  }

  .crosssale .crosssale-carousel .crosssale-carousel-item .controls a {
    display: inline-block;
    background-color: #56ab2f;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: white;
    text-decoration: none;
    margin-bottom: 30px;
    text-align: center;
    padding-top: 12px;
    box-sizing: border-box;
    margin-left: 10px;
    transition: all 0.3s;
  }

  .crosssale .crosssale-carousel .crosssale-carousel-item .controls a:hover {
    background-color: #48701e;
  }

  @media (min-width: 776px) {
    .crosssale h2 {
      display: block;
    }

    .crosssale .button-holder {
      text-align: right;
      margin-bottom: 0;
    }
  }

  @media (min-width: 991px) {
    .crosssale .crosssale-carousel .crosssale-carousel-item .controls {
      display: block;
    }
  }

  .recipe-process {
    padding: 50px 0;
  }

  .recipe-process h2 {
    color: #111111;
    font-family: 'Nunito Sans';
    font-size: 32px;
    font-weight: 800;
    line-height: 43px;
    text-align: center;
    margin-bottom: 50px;
  }

  .recipe-process .recipe-step {
    background: white;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
  }

  .recipe-process .recipe-step img {
    max-width: 100%;
  }

  .recipe-process .recipe-step .recipe-step-content {
    padding: 20px 30px 40px 30px;
  }

  .recipe-process .recipe-step .recipe-step-content h3 {
    color: #111111;
    font-family: 'Nunito Sans';
    font-size: 24px;
    font-weight: 800;
    line-height: 32px;
    margin: 0 0 10px;
  }

  .recipe-process .recipe-step .recipe-step-content h3::first-letter {
    color: #56ab2f;
    font-family: 'Nunito Sans';
    font-size: 32px;
    font-weight: 800;
    line-height: 43px;
  }

  .recipe-process {
  }
  .recipe-process {
  }
`;

const ProductSingle = ({ product, breadcrumbs, isLoading = false, t }) => {
  if (_.isEmpty(product)) {
    return (
      <NotFound title={t('product not found')} imageAlternativeText="404" />
    );
  }

  const [activeTab, setActiveTab] = useState(0);

  const {
    id,
    name = '',
    subtitle = '',
    relatedProducts = [],
    availability,
    storeStatusAvailable,
    storeStatusUnavailable
  } = product;

  return (
    <S.ProductWrapper>
      <Container>
        <Row>
          <Column size={{ xs: 12 }}>
            <BlockTransparent
              marginConfig={{ bottom: true, top: true }}
              paddingConfig={{}}
            >
              <Breadcrumbs data={breadcrumbs} withSameLevels={false} />
            </BlockTransparent>
          </Column>
        </Row>

        <Row>
          <Column size={{ xs: 12, md: 6 }}>
            <div className={'profile-photo'} style={{ position: 'relative', backgroundColor: 'white' }}>
              <Sticker product={product} />
              <Gallery
                image={_.get(product, 'photo', null)}
                gallery={_.get(product, 'gallery', [])}
                showThumbnails={true}
                alternativeImageTitle={_.get(product, 'name', null)}
                settings={{
                  image: {
                    resolution: '1200x800.jpg',
                    resolutionThumb: '590x442.jpg'
                  },
                  gallery: {
                    resolution: '1200x800.jpg',
                    resolutionThumb: '140x80__cropped.png'
                  }
                }}
              />
            </div>
          </Column>
          <Column size={{ xs: 12, md: 6 }}>
            <h1>{name}</h1>
            {subtitle ? <div className={'subtitle'}>{subtitle}</div> : null}
            <div className={'prices'}>
              <div className="selling-price" style={{ fontSize: '1em' }}>
                <ProductPrice product={product} />
              </div>
              {_.get(product, 'standardPriceData.price') ? (
                <div className="origin-price">
                  {_.get(product, 'standardPriceData.priceFormatted')}
                </div>
              ) : null}
            </div>

            <Availability
              availability={availability}
              text={
                availability ? storeStatusAvailable : storeStatusUnavailable
              }
            />
            <div className="add-to-cart">
              <ProductForm product={product} />
            </div>

            <div className="tabs-wrapper">
              <div className="tabs">
                <span
                  key={'product-headline-tab-0'}
                  onClick={() => setActiveTab(0)}
                  className={activeTab === 0 ? 'active' : ''}
                >
                  Čo je obsahom
                </span>
                {_.map(
                  _.get(product, 'additionalInfos', []),
                  (record, index) => {
                    return (
                      <span
                        key={'product-headline-tab-' + record.id}
                        onClick={() => setActiveTab(record.id)}
                        className={activeTab === record.id ? 'active' : ''}
                      >
                        {_.get(record, 'title', '')}
                      </span>
                    );
                  }
                )}
              </div>

              {activeTab === 0 && (
                <div key={'product-content-tab-0'} className="tab-content">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: _.get(
                        product,
                        'productDescription.description',
                        ''
                      )
                    }}
                  ></div>
                </div>
              )}

              {_.map(_.get(product, 'additionalInfos', []), (record, index) => {
                if (activeTab !== record.id) {
                  return null;
                }
                return (
                  <div
                    key={'product-content-tab-' + record.id}
                    className="tab-content"
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: _.get(record, 'text', '')
                      }}
                    ></div>
                  </div>
                );
              })}
            </div>
          </Column>
        </Row>
        {!_.isEmpty(relatedProducts) ? (
          <Fragment>
            <BlockTransparent margin="80px 0 20px 0" padding="0">
              <Headline2>Mohlo by Vás zaujímať</Headline2>
            </BlockTransparent>
            <Row>
              {_.map(_.slice(relatedProducts, 0, 4), (product, i) => {
                const productId = _.get(product, 'id', i);
                return (
                  <Column
                    size={{ xs: 6, sm: 4, md: 3 }}
                    key={'products-latest-column-' + productId}
                    style={{ marginBottom: '20px' }}
                  >
                    <ProductCard
                      data={product}
                      showTitleOnTop={false}
                      showBrandOnTop={false}
                      showBrand={false}
                      showLabels={true}
                      lazyload={false}
                    />
                  </Column>
                );
              })}
            </Row>
          </Fragment>
        ) : (
          ''
        )}
        <Row>
          <Column size={{ xs: 12 }}>
            <Newsletter
              config={{
                title: 'Odber noviniek',
                content:
                  'Dávam dobrovoľný súhlas na zasielanie bezplatného newslettra na mnou zadaný e-mail. Tento súhlas môžem kedykoľvek odvolať na adrese info@freshbox.sk. Oboznámil/a som sa s informáciami v sekcií <a href="/stranka/informacie-o-spracuvani-osobnych-udajov">ochrana osobných údajov</a>.'
              }}
            />
          </Column>
        </Row>
        {isLoading ? (
          <SS.Overlay>
            <img src={loader} alt="loader" />
          </SS.Overlay>
        ) : (
          ''
        )}
      </Container>
    </S.ProductWrapper>
  );
};

const ProductBox = ({ product, breadcrumbs, isLoading = false, t }) => {
  const { name, relatedProducts = [] } = product;
  const shortDescription = _.get(
    product,
    'shortProductDescription.description',
    null
  );
  const longDescription = _.get(product, 'productDescription.description', '');
  const ingredientsPhoto = _.get(product, 'gallery[0]');
  const boxVideo = _.find(
    _.get(product, 'additionalInfos', []),
    item => item.hash == BOX_HASH_VIDEO
  );
  return (
    <S.ProductWrapper>
      <Container>
        <Row>
          <Column size={{ xs: 12 }}>
            <BlockTransparent
              marginConfig={{ bottom: true, top: true }}
              paddingConfig={{}}
            >
              <Breadcrumbs data={breadcrumbs} withSameLevels={false} />
            </BlockTransparent>
          </Column>
        </Row>
        <Row>
          <Column size={{ xs: 12, md: 5 }}>
            <h1>{name}</h1>
            <div className="top-parameters">
              {_.map(
                _.slice(_.get(product, 'detailedParameters', []), 0, 3),
                parameter => {
                  return (
                    <div
                      className="parameter"
                      key={'parameter-key-id-' + parameter.id}
                    >
                      {_.get(parameter, 'photoPath') ? (
                        <Image
                          path={parameter.photoPath}
                          size={`58x58.png`}
                          alt={parameter.name}
                          lazyload={false}
                        />
                      ) : null}
                      <div className="parameter-name">{parameter.name}</div>
                      <div className="parameter-value">{parameter.value}</div>
                    </div>
                  );
                }
              )}
            </div>
            {shortDescription && (
              <div
                className="short-description"
                dangerouslySetInnerHTML={{
                  __html: shortDescription
                }}
              ></div>
            )}
            <div className="prices">
              <div className="selling-price" style={{ fontSize: '1.3em' }}>
                <ProductPrice product={product} />
              </div>
            </div>

            <div className="add-to-cart">
              <ProductForm product={product} />
            </div>
          </Column>
          <Column size={{ xs: 12, md: 1 }}></Column>
          <Column size={{ xs: 12, md: 6 }}>
            <div className={'profile-photo'} style={{ position: 'relative', backgroundColor: 'white' }}>
              <Sticker product={product} />
              <Gallery
                image={_.get(product, 'photo', null)}
                gallery={_.get(product, 'gallery', [])}
                showThumbnails={false}
                alternativeImageTitle={_.get(product, 'name', null)}
              />
            </div>
          </Column>
        </Row>
        <Row className={'ingredients-section'}>
          <Column size={{ xs: 12, md: 8 }}>
            {ingredientsPhoto && (
              <S.Image
                className={'ingredients-photo'}
                path={ingredientsPhoto.path}
                size={`780x410__cropped.jpg`}
                alt={_.get(
                  ingredientsPhoto,
                  'title',
                  _.get(ingredientsPhoto, 'description')
                )}
                lazyload={false}
              />
            )}
          </Column>
          <Column size={{ xs: 12, md: 4 }}>
            <div
              dangerouslySetInnerHTML={{
                __html: longDescription
              }}
            ></div>
          </Column>
        </Row>
        {!_.isEmpty(relatedProducts) ? (
          <Fragment>
            <BlockTransparent margin="80px 0 20px 0" padding="0">
              <Headline2>Mohlo by Vás zaujímať</Headline2>
            </BlockTransparent>
            <Row>
              {_.map(_.slice(relatedProducts, 0, 4), (product, i) => {
                const productId = _.get(product, 'id', i);
                return (
                  <Column
                    size={{ xs: 6, sm: 4, md: 3 }}
                    key={'products-latest-column-' + productId}
                    style={{ marginBottom: '20px' }}
                  >
                    <ProductCard
                      data={product}
                      showTitleOnTop={false}
                      showBrandOnTop={false}
                      showBrand={false}
                      showLabels={true}
                      lazyload={false}
                    />
                  </Column>
                );
              })}
            </Row>
          </Fragment>
        ) : (
          ''
        )}
        {boxVideo || _.get(product, 'additionalInfos', []).length ? (
          <div className={'recipe-process'}>
            <h2>Postup varenia</h2>
            <Container>
              {boxVideo ? (
                <Row>
                  <Column size={{ xs: 12, md: 3 }}>&nbsp;</Column>
                  <Column
                    size={{ xs: 12, md: 6 }}
                    key={'product-box-video-' + boxVideo.id}
                  >
                    <div
                      className="recipe-step"
                      style={{ padding: '9px 9px 0 9px' }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: _.get(boxVideo, 'text', '')
                        }}
                      ></div>
                    </div>
                  </Column>
                </Row>
              ) : null}
              <Row>
                {_.map(_.get(product, 'additionalInfos', []), record => {
                  if (_.get(record, 'hash', '') == BOX_HASH_VIDEO) {
                    return null;
                  }
                  return (
                    <Column
                      size={{ xs: 12, md: 6 }}
                      key={'product-additional-' + record.id}
                    >
                      <div className="recipe-step">
                        {_.get(record, 'photo') && (
                          <S.Image
                            path={_.get(record, 'photo.path')}
                            size={`600x340__cropped.jpg`}
                            alt={_.get(
                              record,
                              'photo.title',
                              _.get(record, 'title')
                            )}
                            lazyload={false}
                          />
                        )}
                        <div className="recipe-step-content">
                          <h3>{_.get(record, 'title', '')}</h3>
                          <div
                            className="text"
                            dangerouslySetInnerHTML={{
                              __html: _.get(record, 'text', '')
                            }}
                          ></div>
                        </div>
                      </div>
                    </Column>
                  );
                })}
              </Row>
            </Container>
          </div>
        ) : null}
        <Row>
          <Column size={{ xs: 12 }}>
            <Newsletter
              config={{
                title: 'Odber noviniek',
                content:
                  'Dávam dobrovoľný súhlas na zasielanie bezplatného newslettra na mnou zadaný e-mail. Tento súhlas môžem kedykoľvek odvolať na adrese info@freshbox.sk. Oboznámil/a som sa s informáciami v sekcií <a href="/stranka/informacie-o-spracuvani-osobnych-udajov">ochrana osobných údajov</a>.'
              }}
            />
          </Column>
        </Row>
      </Container>
    </S.ProductWrapper>
  );
};

S.Column = styled(Column)`
  position: relative;
`;

class Product extends PureComponent {
  componentDidMount() {
    const { product, addToLastSeenProducts } = this.props;
    const externalUrl = _.get(product, 'externalUrl');

    if(externalUrl) {
      gotoUrl(externalUrl);
    }

    if (!_.isEmpty(product)) {
      addToLastSeenProducts(product);
    }

    fireGTMEvent(PRODUCT_DETAIL, [product]);
  }
  render() {
    const { product, breadcrumbs, isLoading = false, t } = this.props;

    const breadCrumbsCategorical = _.get(breadcrumbs, 'categorical', []);
    const breadCrumbsData = _.concat(breadCrumbsCategorical, [
      { id: _.get(product, 'id'), name: _.get(product, 'name') }
    ]);

    if (_.get(product, 'kind.id') == BOX_ID) {
      return (
        <ProductBox
          product={product}
          breadcrumbs={breadCrumbsData}
          isLoading={isLoading}
          t={t}
        />
      );
    }
    return (
      <ProductSingle
        product={product}
        breadcrumbs={breadCrumbsData}
        isLoading={isLoading}
        t={t}
      />
    );
  }
}

const ProductConnected = connect(
  makeMapStateToProps,
  mapDispatchToProps
)(Product);

export default withTranslation()(ProductConnected);
