import React, { PureComponent } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import { Column, Container, Row } from 'common/components/styleguide/grid';

import NotFound from 'common/components/errors/404';
import Simple from 'common/components/static/Simple';

import { getPageDataSelector } from 'common/selectors/page';
import SS from 'common/pages/_style/index';

class Static extends PureComponent {
  render() {
    const { data, showContactForm = false } = this.props;
    if (!_.isEmpty(data)) {
      return (
        <SS.Wrapper>
          <Container>
            <Row>
              <Column size={{ xs: 12 }}>
                <Simple data={data} showContactForm={showContactForm} />
              </Column>
            </Row>
          </Container>
        </SS.Wrapper>
      );
    }
    return <NotFound />;
  }
}

const makeMapStateToProps = () => {
  const getPageData = getPageDataSelector();
  return (state, props) => {
    const pageData = getPageData(state, props);
    return {
      showContactForm: _.get(pageData, 'showContactForm'),
      data: _.get(pageData, 'staticPage')
    };
  };
};

const StaticConnected = connect(makeMapStateToProps)(Static);

export default StaticConnected;
