import React, { Fragment } from 'react';
import _ from 'lodash';

import { Container, Row, Column } from 'common/components/styleguide/grid';
import ContactForm from 'common/blocks/contact/element';

const Simple = ({ data, showContactForm = false }) => {
  const {
    title,
    customCss = '',
    customJavascript = '',
    content = ''
  } = data;

  if (!_.isEmpty(data)) {
    const {
      title,
      customCss = '',
      customJavascript = '',
      content = ''
    } = data;
    return (
      <Fragment>
        {customCss ? (
          <style
            dangerouslySetInnerHTML={{
              __html: customCss
            }}
          />
        ) : null}
        <Container>
          <Row>
            <Column size={{ xs: 12 }}>
              <h1>{title}</h1>
            </Column>
          </Row>
          {showContactForm ? (
            <Row>
              <Column size={{ xs: 12 }}>
                <ContactForm />
              </Column>
            </Row>
          ) : null}
          <Row>
            <Column size={{ xs: 12 }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: content
                }}
              />
            </Column>
          </Row>
        </Container>
        {customJavascript ? (
          <div
            dangerouslySetInnerHTML={{
              __html: customJavascript
            }}
          />
        ) : null}
      </Fragment>
    );
  }
  return null;
}

export default Simple;
