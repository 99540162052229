import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import loadable from '@loadable/component';

import NotFound from 'common/components/errors/404';
import { reactiveTemplateConfigSelector } from 'common/selectors/theme';
import { getPageDataSelector } from 'common/selectors/page';

import { capfl } from 'common/utils';

const BlogDetailVariant = loadable(props =>
  import(`common/blocks/blog/detail/${props.blockVariant}`)
);


class BlogDetail extends Component {
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const {
      t,
      article = null,
      latestArticles = [],
      blockSettings
    } = this.props;
    if (article) {
      const { category } = article;
      const breadcrumbs = [
        {
          id: 'home',
          name: capfl(t('home')),
          link: {
            route: 'home'
          }
        },
        {
          id: 'blog',
          name: capfl(t('blog')),
          link: {
            route: 'blog'
          }
        },
        {
          id: _.get(category, 'id'),
          name: _.get(category, 'name'),
          urlName: _.get(category, 'urlName'),
          link: {
            href: '/blog?category=' + _.get(category, 'id')
          }
        },
        {
          id: _.get(article, 'id'),
          name: _.get(article, 'title'),
          urlName: _.get(article, 'urlTitle')
        }
      ];

      return (
        <BlogDetailVariant
          blockVariant={_.get(blockSettings, 'blockVariant')}
          breadcrumbs={breadcrumbs}
          data={article}
          latestArticles={latestArticles}
          t={t}
        />
      )
    }
    return <NotFound />;
  }
}

const makeMapStateToProps = () => {
  const getPageData = getPageDataSelector();
  const reactiveTemplateConfig = reactiveTemplateConfigSelector('BlogDetailVariant');
  const mapStateToProps = (state, props) => {
    const pageData = getPageData(state, props);

    return {
      article: _.get(pageData, 'article', null),
      latestArticles: _.get(pageData, 'latestArticles', null),
      blockSettings: {
        blockVariant: reactiveTemplateConfig(state, props)
      }
    };
  };

  return mapStateToProps;
};

const BlogDetailConnected = connect(makeMapStateToProps)(BlogDetail);

export default withTranslation()(BlogDetailConnected);
